@tailwind base;
@tailwind components;
@tailwind utilities;

.carousel-item{
    width: 50% !important;
}
.carousel-container{
    display: flex;
    justify-content: center;
    background-color: white !important;
}
.next{
    background-color: black;
}
.prev{
    background-color: black;
}